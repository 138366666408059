@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
	margin: 0;
	padding: 0;
	font-family: "Montserrat";
	text-decoration: none;
	border-radius: 0;
	border: none;
	color: black;
}

*:focus {
	outline: none;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

html,
body {
	width: 100%;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

#root {
	width: 100%;
}

.Menu-Open {
	height: 100vh;
	overflow: hidden;
}

h1 {
	font-size: 2.5rem;
}

.Path-Container {
	float: left;
	min-height: 100vh;
	width: calc(100% - 150px);
	background: rgb(231, 220, 209);
	background: linear-gradient(210deg,
			rgba(231, 220, 209, 0.2) 0%,
			rgba(231, 220, 209, 0.8) 100%);
	background: linear-gradient(210deg,
			rgba(231, 220, 209, 0.1) 0%,
			rgb(219 205 192) 100%);
	overflow: hidden;
}

.Path-Container.Menu-Open {
	display: none;
}

.P-W {
	position: relative;
	height: 100%;
	z-index: 2;
	width: calc(100% - 150px);
}

.Padded {
	padding-left: 100px !important;
	padding-right: 100px !important;
}

.P-W h1 {
	padding-top: 200px;
	padding-bottom: 20px;
	position: relative;
}

.hidden {
	display: none !important;
}

.invisible {
	opacity: 0 !important;
}

@media screen and (max-width: 1180px) {
	.Padded {
		padding-left: 80px !important;
		padding-right: 80px !important;
	}
}

@media screen and (max-width: 1080px) {
	.Path-Home .P-W h1 {
		padding-top: 100px;
	}
}

@media screen and (max-width: 780px) {
	.Padded {
		padding-left: 50px !important;
		padding-right: 50px !important;
	}
}

@media screen and (max-width: 572px) {
	.Path-Container {
		width: 100%;
	}
}

@media screen and (max-width: 460px) {
	.Padded {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}