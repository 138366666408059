.Offert-Wrapper {
	z-index: 20;
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
}

.Offert-Form {
	transform: translateY(-50%) translateX(-50%);
	position: absolute;
	left: 50%;
	top: 50%;
	width: 400px;
	background-color: white;
	padding: 30px;
	border-radius: 4px;
	-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

.Offert-Form form.Hidden {
	display: none;
}

.Offert-Form input,
.Offert-Form textarea {
	width: 100%;
	resize: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 8px 0px;
	margin-top: 3px;
	margin-bottom: 3px;
}

.Offert-Form h1 {
	font-size: 2.3rem;
	font-weight: 300;
	margin-bottom: 10px;
	background: -webkit-linear-gradient(#4286f4, #373b44);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.Offert-Form p {
	margin-bottom: 30px;
}

.Offert-Form button {
	cursor: pointer;
	padding: 6px 20px;
	background-color: #254961;
	color: white;
	font-size: 1rem;
	border-radius: 4px;
}

.Offert-Loading.Hidden {
	display: none;
}

.Exit-ContactForm {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 1.5rem;
	cursor: pointer;
}

.Exit-ContactForm:hover {
	color: rgba(0, 0, 0, 0.8);
}

.Offert-Form .Wrap-Input {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 35px;
	margin-bottom: 25px;
}

.Input {
	border: none;
	border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.Input:focus {
	border-bottom: 2px solid #0056b3;
}

.Input:focus+.Focus-Input {
	top: -16px;
	background: -webkit-linear-gradient(#4286f4, #373b44);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.Input.Has-Value+.Focus-Input {
	top: -16px;
	background: -webkit-linear-gradient(#4286f4, #373b44);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.Input.Has-Value {
	border-bottom: 2px solid #0056b3;
}

.Focus-Input {
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 0;
}

@media screen and (max-width: 820px) {
	.Offert-Form {
		position: fixed;
		height: 100vh;
		box-sizing: border-box;
		width: 100%;
		padding: 50px 30px;
		border-radius: 0px;
		top: 0;
		left: 0;
		transform: translateY(0%) translateX(0%) !important;
	}
}