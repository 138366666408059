#Login-Form {
	background-color: white;
	padding: 10px;
	padding-top: 80px;
	width: 500px;
	display: flex;
	flex-direction: row;
	position: fixed;
	top: 0px;
	right: 150px;
	z-index: 6;
	transform: translate(0%, -100%);
	transition: all 0.2s ease-in-out;
}

#Login-Form.Login-Form-Show {
	transform: translateY(0%) !important;
	-webkit-box-shadow: -1px 1px 5px 2px rgb(0 0 0 / 20%);
	-moz-box-shadow: -1px 1px 5px 2px rgb(0 0 0 / 20%);
	box-shadow: -1px 1px 5px 2px rgb(0 0 0 / 20%);
	transform: translate(0%, 0%);
}

#Login-Form form {
	display: block;
	width: 100%;
	padding: 20px;
}

#Login-Form input,
#Login-Form button,
#Login-Form h3,
#Login-Form p {
	display: block;
}

#Login-Form h3 {
	color: #254961;
}

#Login-Form button {
	cursor: pointer;
	padding: 5px 20px;
	border-radius: 4px;
	background: #254961;
	color: white;
}

#Login-Form .Wrap-Input {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
	margin-bottom: 25px;
}

#Login-Form .Wrap-Input input {
	width: 100%;
	padding: 10px 0px;
}

.Exit-Login {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 2rem;
	cursor: pointer;
}

.Login-Info {
	padding: 15px !important;
	position: relative;
	font-size: 0.7rem;
	align-self: center;
	line-height: 1.5;
	width: 380px;
}

.Login-Input {
	left: 3px;
}

@media screen and (max-width: 980px) {
	#Login-Form {
		padding: unset;
		padding-top: 80px;
		transform: translate(0%, -230%);
		width: calc(100% - 150px);
		flex-direction: row;
		top: 0;
		height: 100%;
		left: 0;
		right: unset;
		z-index: 10;
		flex-direction: column;
		padding-left: 50px;
		padding-right: 50px;
		box-sizing: border-box;
	}

	#Login-Form form {
		padding: 0;
	}

	#Login-Form .Login-Info {
		width: 100%;
		padding: 20px;
		margin-top: 30px;
		text-align: center;
	}

	#Login-Form.Login-Form-Show {
		transform: translateY(0%) !important;
		-webkit-box-shadow: -1px 1px 5px 2px rgb(0 0 0 / 20%);
		-moz-box-shadow: -1px 1px 5px 2px rgb(0 0 0 / 20%);
		box-shadow: -1px 1px 5px 2px rgb(0 0 0 / 20%);
		transform: translate(0%, 0%);
		opacity: 1;
	}
}

@media screen and (max-width: 572px) {
	#Login-Form {
		width: 100%;
	}
}