.User-Box {
  background: white;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

.User-Box div {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.User-Info {
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  width: 300px;
}

.User-Info div label {
  font-weight: 700;
}

@media screen and (max-width: 572px) {
  .User-Info {
    width: 100%;
  }
}
