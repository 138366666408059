.LoadingBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	box-sizing: border-box !important;
	text-align: center;
}

.LoadingBox img {
	width: 80px;
	margin-bottom: 20px;
}

.LoadingText {}