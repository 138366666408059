.MessageBox {
	position: absolute;
	background-color: whitesmoke;
	color: black;
	bottom: 0;
	left: 0;
	padding: 20px;
	z-index: 25;
	border-top-right-radius: 10px;
	max-width: 250px;
	word-break: break-all;
	transform: translateX(-100%);
	transition: all .3s ease;
	overflow: hidden;
}

.MessageBox.show {
	transform: translateX(0%);
}