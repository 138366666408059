.Path-Home {
	float: left;
	width: 100%;
}

.Path-Home h1 {
	display: inline-block;
	font-weight: 100;
	width: 750px;
	font-size: 4rem;
	margin-top: 80px;
}

.Path-Home h3 {
	width: 750px;
	padding-top: 0px;
	font-size: 1.3rem;
	font-weight: 300;
}

.Home-BG-Wrapper {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	max-height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
}

.Home-BG {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 0;
	opacity: 0.18;
	transition: all 1s ease-in-out;
	pointer-events: none;
}

.Home-BG-2 {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	opacity: 0.18;
	transition: all 1s ease-in-out;
	pointer-events: none;
}

.Home-BG.Video-Done,
.Home-BG-2.Video-Done {
	opacity: 0 !important;
}

.Path-Home button {
	border: none;
	padding: 8px 16px;
	font-size: 1.3rem;
	font-weight: 300;
	color: white;
	background: #254961;
	margin-bottom: 20px;
	margin-top: 20px;
	border-radius: 4px;
	cursor: pointer;
	float: left;
	position: relative;
}

.Home-Iphone {
	position: absolute;
	background: rgba(57, 136, 167, 0.6);
	width: 430px;
	height: 600px;
	right: 400px;
	bottom: 100px;
	border-top-left-radius: 10px;
	border-top-right-radius: 300px;
	border-bottom-left-radius: 300px;
	border-bottom-right-radius: 50px;
	z-index: 5;
}

@media screen and (max-width: 1680px) {
	.Path-Home h1 {
		width: 60%;
	}

	.Path-Home h3 {
		width: 50%;
	}

	.Home-BG-Wrapper {
		width: 100%;
		max-height: 100vh;
		height: 100vh;
		position: absolute;
		overflow: hidden;
	}

	.Home-BG {
		width: auto;
	}

	.Home-BG-2 {
		width: auto;
	}
}

@media screen and (max-width: 1680px) {
	.Path-Home h1 {
		width: 55%;
	}

	.Home-Iphone {
		right: 250px;
	}
}

@media screen and (max-width: 1580px) {
	.Path-Home h1 {
		width: 60%;
		font-size: 3.5rem;
	}

	.Home-Iphone {
		bottom: 75px;
	}
}

@media screen and (max-width: 1480px) {
	.Path-Home h1 {
		font-size: 3rem;
	}

	.Home-Iphone {
		width: 420px;
		height: 575px;
		bottom: 50px;
		right: 230px;
	}

	.iPhone-Wrapper {
		transform: translate(26%, 6%);
	}
}

@media screen and (max-width: 1380px) {
	.Home-Iphone {
		width: 390px;
		height: 575px;
	}

	.iPhone-Wrapper {
		transform: translate(18%, 6%);
	}

	.Path-Home h1 {
		width: 62%;
	}
}

@media screen and (max-width: 1280px) {
	.Path-Home h1 {
		font-size: 2.8rem;
		width: 85%;
	}

	.Home-Iphone {
		height: 560px;
		width: 330px;
		right: 200px;
		bottom: 40px;
		border-top-left-radius: 80px;
		border-top-right-radius: 300px;
		border-bottom-left-radius: 300px;
		border-bottom-right-radius: 125px;
	}

	.iPhone-Wrapper {
		transform: translate(10%, 8%);
	}

	.Home-BG {
		z-index: 0;
		left: -140px;
	}

	.Home-BG-2 {
		left: -140px;
	}
}

@media screen and (max-width: 1180px) {
	.Path-Home h1 {
		font-size: 2.6rem;
	}

	.Home-Iphone {
		height: 560px;
		width: 330px;
		border-top-left-radius: 80px;
		border-top-right-radius: 300px;
		border-bottom-left-radius: 300px;
		border-bottom-right-radius: 125px;
	}

	.iPhone-Wrapper {
		transform: translate(10%, 8%);
	}
}

@media screen and (max-width: 1060px) {
	.Home-Iphone {
		display: none;
	}

	.Path-Home h3 {
		width: 70%;
	}

	.Path-Home h1 {
		margin-top: 100px;
	}
}

@media screen and (max-width: 980px) {
	.Home-BG {
		z-index: 0;
		left: -200px;
	}

	.Home-BG-2 {
		left: -200px;
	}
}

@media screen and (max-width: 880px) {
	.Path-Home h1 {
		font-size: 2.5rem;
		margin-top: 50px;
		width: 100%;
	}

	.Path-Home h3 {
		font-size: 1.3rem;
		width: 100%;
	}

	.Home-BG {
		z-index: 0;
		left: -240px;
	}

	.Home-BG-2 {
		left: -240px;
	}
}

@media screen and (max-width: 780px) {
	.Path-Home h1 {
		font-size: 2.5rem;
	}

	.Path-Home h3 {
		font-size: 1.3rem;
	}

	.Home-BG {
		z-index: 0;
		left: -260px;
	}

	.Home-BG-2 {
		left: -260px;
	}
}

@media screen and (max-width: 680px) {
	.Path-Home h1 {
		font-size: 2.3rem;
	}

	.Path-Home h3 {
		font-size: 1.3rem;
	}

	.Home-BG {
		z-index: 0;
		left: -320px;
	}

	.Home-BG-2 {
		left: -320px;
	}
}

@media screen and (max-width: 580px) {
	.Home-BG {
		z-index: 0;
		left: -540px;
	}

	.Home-BG-2 {
		left: -540px;
	}
}

@media screen and (max-width: 416px) {
	.Path-Home h1 {
		font-size: 2.2rem;
	}
}

@media screen and (max-width: 400px) {
	.Path-Home h1 {
		font-size: 1.9rem;
	}

	.Path-Home h3 {
		font-size: 1.1rem;
	}
}