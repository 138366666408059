.Path-Portfolio {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	text-align: center;
}

.P-W {
	width: 100%;
}

.Portfolio-Projects {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 100px;
	margin-top: 20px;
}

.Portfolio-Project {
	position: relative;
	display: flex;
	justify-content: center;
	align-content: center;
	overflow: hidden;
	width: 48%;
	min-height: 200px;
	transition: all .3s ease;
	background-color: rgba(255, 255, 255, 0.5);
	-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	margin-bottom: 40px;
}

.Portfolio-Project.rotated {
	transform: rotate3d(0, 1, 0, 180deg);
}

.Portfolio-Project.rotated img,
.Portfolio-Project.rotated span {
	opacity: 0;
}

.Portfolio-Project.rotated>.Portfolio-Project-Title {
	opacity: 1;
}

.Portfolio-Project-Title {
	position: absolute;
	box-sizing: border-box;
	color: black;
	width: 100%;
	font-weight: 300;
	top: 50%;
	transform: translateY(-50%) rotate3d(0, 1, 0, 180deg);
	text-align: center;
	z-index: 1;
	pointer-events: none;
	opacity: 0;
}

.Portfolio-Project-Title p {
	padding: 0px 30px !important;
}

.Portfolio-Project div h3 {
	font-weight: 600;
	font-size: 1.3rem;
}

.Portfolio-Project div h3,
.Portfolio-Project div p {
	padding: 5px;
	pointer-events: none;
}

.Portfolio-Project img {
	max-width: 200px;
	max-height: 100px;
	pointer-events: none;
	transition: all .2s ease;
}

.Portfolio-Project-Image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	pointer-events: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

.Portfolio-Project:hover .Portfolio-Project-Image img {
	transform: scale(1.1);
}

.Portfolio-Project-Image span {
	font-size: 0.6rem;
	position: absolute;
	bottom: 5%;
	left: 50%;
	transform: translate(-50%, 0%);
}

@media screen and (max-width: 980px) {
	.Portfolio-Projects {
		flex-direction: column;
	}

	.Portfolio-Project {
		width: 100%;
		min-width: 100%;
		height: inherit;
		margin: 10px 0px;
	}
}

@media screen and (max-width: 572px) {
	.Portfolio-Project-Wrapper {
		width: 100vw !important;
	}
}