.Path-About {
	float: left;
	width: 100%;
}

.Path-About button {
	border: none;
	font-size: 1.3rem;
	padding: 8px 16px;
	font-weight: 300;
	color: white;
	background: #254961;
	border-radius: 5px;
	cursor: pointer;
	float: left;
	position: relative;
}

.Info-Start {
	margin-bottom: 100px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.About-Information {
	display: flex;
	flex-direction: column;
	max-width: 900px;
	justify-content: center;
	align-items: center;
}

.About-Information h2 {
	font-size: 1.3rem;
	font-weight: 500;
	margin-bottom: 20px;
}

.About-Information p {
	margin-bottom: 20px;
}

.About-Cards * {
	box-sizing: border-box;
}

.About-Cards {
	display: flex;
	gap: 50px;
	justify-content: center;
	flex-wrap: wrap;
	padding: 70px;
}

.About-Card {
	display: flex;
	align-items: center;
	padding: 20px;
	background: rgba(255, 255, 255, 0.5);
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
	width: 400px;
}

.About-Card-Image {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 100px;
	height: 150px;
	width: 150px;
	margin-right: 10px;
}

.About-Card-Image img {
	max-width: 230px;
	margin-top: 40px
}

.About-Card-Image.Fixed img {
	margin-left: -20px
}

.About-Card-Contact {
	display: flex;
	flex-direction: column;
}

.About-Card-Contact .email {
	font-size: 13px;
	color: #2371d3;
}

.About-Card-Contact .title {
	font-style: italic;
	font-size: 12px;
}

.About-Card-Contact p:first-child,
.About-Card-Contact p:last-child {
	margin: 20px 5px;
	margin-left: -5px;
}

.About-Exps {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
	gap: 40px;
	flex-wrap: wrap;
	padding-top: 50px;
	padding-bottom: 50px;
}

.About-Exps li {
	padding-left: 0px;
	margin: 8px 0px;
}

.About-Exps li::marker {
	color: #254961;
}

.About-Information p {
	font-size: 1.2rem;
	font-weight: 300;
}

.About-Flow {
	display: flex;
	justify-content: space-between;
	justify-content: center;
	gap: 10%;
	background: rgba(255, 255, 255, 0.5);
	padding: 30px;
}

.About-Flow>div {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.About-Flow div span {
	display: flex;
	align-items: center;
}

.About-Flow .logo {
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
	background-color: white;
	border-radius: 8px;
	overflow: hidden;
	flex: 0 0 48px;
}

.About-Flow .logo.height img {
	max-height: 35px;
}

.About-Flow img {
	max-width: 48px;
	max-height: 48px;
}

.About-Flow p {
	padding-left: 10px;
}


.BankID-Image {
	border-radius: 10px;
	-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1130px) {
	.About-Wrapper {
		flex-direction: column;
	}
}

@media screen and (max-width: 980px) {
	.About-Flow {
		flex-direction: column;
		gap: 10px;
	}

	.About-Information-Title h2 {
		font-size: 1.2rem;
	}

	.About-Information-Title p {
		font-size: 1.1rem;
	}

	.About-Card-Contact p {
		font-size: 13px;
	}
}