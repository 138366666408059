.header {
	position: fixed;
	height: 80px;
	width: calc(100% - 150px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
	transition: background .2s ease;
}

.header.scrolled {
	background: white;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 9px -6px #707070;
	box-shadow: 0px 0px 9px -6px #707070;
}

.header .logotype {
	max-height: 26px;
	margin-left: 30px;
}

.header-menu {
	display: flex;
	justify-content: center;
	align-items: center;
}

.header-menu ul.menu-items {
	display: flex;
	list-style: none;
}

.header-menu ul.menu-items li {
	margin: 0px 30px;
}

.header-menu ul.menu-items li a {
	line-height: 25px;
}

.header-menu ul.menu-items li a.active {
	color: #2371d3 !important;
}

.header-menu .header-login-btn {
	cursor: pointer;
	background: linear-gradient(to bottom, #4286f4, #373b44);
	color: white;
	padding: 3px 8px;
	border-radius: 4px;
}

.header-menu ul.social-icons {
	display: none;
}

.header-menu ul.social-icons li {
	color: white;
	font-size: 28px;
}

@media screen and (max-width: 980px) {
	.burger {
		display: inline-block;
		cursor: pointer;
		width: 60px;
		height: 60px;
		margin-right: 20px;
		border-radius: 50%;
		line-height: 0.6;
		z-index: 10;
		background-color: transparent;
	}

	.header-menu.active .burger span::before,
	.header-menu.active .burger span::after {
		background-color: white;
	}

	.burger>span {
		display: inline-block;
		position: relative;
		height: 2px;
		width: 34px;
		border-radius: 1px;
		background: #293335;
		vertical-align: middle;
	}

	.burger>span:before,
	.burger>span:after {
		display: inline-block;
		position: absolute;
		content: "";
		height: 2px;
		border-radius: 1px;
		background: #293335;
		transition: all 300ms;
	}

	.burger>span:before {
		top: -11px;
		left: 3px;
		width: 28px;
	}

	.burger>span:after {
		top: 11px;
		left: 6px;
		width: 22px;
	}

	.burger:focus {
		outline: none;
	}

	.burger:hover>span:after,
	.burger:hover>span:before {
		width: 34px;
		left: 0;
	}

	.active.header-menu .burger>span {
		height: 0;
	}

	.active.header-menu .burger>span:after,
	.active.header-menu .burger>span:before {
		top: -2px;
		left: 0;
		width: 34px;
	}

	.active.header-menu .burger>span:after {
		transform: rotate(-45deg);
	}

	.active.header-menu .burger>span:before {
		transform: rotate(45deg);
	}

	.header-menu:before {
		position: fixed;
		top: 0px;
		left: 0px;
		content: "";
		width: calc(100% - 150px);
		height: 100vh;
		background: rgba(0, 0, 0, 0.9);
		-webkit-transition: clip-path 300ms ease-in-out,
			visibility 300ms ease-in-out, opacity 300ms ease-in-out;
		-moz-transition: clip-path 300ms ease-in-out,
			visibility 300ms ease-in-out, opacity 300ms ease-in-out;
		-o-transition: clip-path 300ms ease-in-out, visibility 300ms ease-in-out,
			opacity 300ms ease-in-out;
		transition: clip-path 300ms ease-in-out, visibility 300ms ease-in-out,
			opacity 300ms ease-in-out;
		clip-path: circle(30px at calc(100% - 65px) 65px);
		visibility: hidden;
		opacity: 0;
	}

	.header-menu.fast:before {
		-webkit-transition: clip-path 300ms ease-in-out,
			visibility 300ms ease-in-out, opacity 300ms ease-in-out;
		-moz-transition: clip-path 300ms ease-in-out,
			visibility 300ms ease-in-out, opacity 300ms ease-in-out;
		-o-transition: clip-path 300ms ease-in-out, visibility 300ms ease-in-out,
			opacity 300ms ease-in-out;
		transition: clip-path 300ms ease-in-out, visibility 300ms ease-in-out,
			opacity 300ms ease-in-out;
	}

	.active.header-menu:before {
		visibility: visible;
		clip-path: circle(100%);
		opacity: 1;
	}

	.header-menu ul.menu-items {
		position: absolute;
		flex-direction: column;
		left: 0;
		top: 80px;
		width: 100%;
		height: calc(75vh - 80px);
		align-items: center;
		display: none;
	}

	.header-menu.active ul.menu-items {
		display: flex;
	}

	.header-menu.active ul.menu-items li {
		opacity: 1;
		margin: 0px;
		transform: translateY(0px);
		transition: all 0.2s ease-in-out;
		margin-bottom: 30px;
	}

	.header-menu ul.menu-items li.transition {
		opacity: 0;
		transform: translateY(-40px);
	}

	.header-menu.active ul.menu-items li a {
		color: white;
		line-height: unset;
		font-size: 28px;
	}

	.header-menu .header-login-btn {
		cursor: pointer;
		background: linear-gradient(to bottom, #4286f4, #373b44);
		color: white;
		padding: 8px 16px;
		font-size: 20px;
		margin-top: 50px !important;
	}
}

@media screen and (max-width: 572px) {
	.header {
		width: 100%;
	}

	.header-menu:before {
		position: fixed;
		top: 0px;
		left: 0px;
		content: "";
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.9);
		-webkit-transition: clip-path 300ms ease-in-out,
			visibility 300ms ease-in-out, opacity 300ms ease-in-out;
		-moz-transition: clip-path 300ms ease-in-out,
			visibility 300ms ease-in-out, opacity 300ms ease-in-out;
		-o-transition: clip-path 300ms ease-in-out, visibility 300ms ease-in-out,
			opacity 300ms ease-in-out;
		transition: clip-path 300ms ease-in-out, visibility 300ms ease-in-out,
			opacity 300ms ease-in-out;
		clip-path: circle(30px at calc(100% - 65px) 65px);
		visibility: hidden;
		opacity: 0;
	}

	.active.header-menu:before {
		visibility: visible;
		clip-path: circle(100%);
		opacity: 1;
	}

	.header-menu ul.social-icons {
		display: flex;
		gap: 40px;
		margin-top: 40px;
	}
}