.sidebar {
	width: 150px;
	height: 100%;
	background: linear-gradient(to bottom, #4286f4, #373b44);
	position: fixed;
	top: 0;
	right: 0;
	opacity: 0.7;
	z-index: 10;
}

.sidebar .social-logos {
	display: flex;
	text-align: center;
	justify-content: center;
	height: 100%;
	flex-direction: column;
}

.sidebar .social-logos li {
	padding: 1.5rem;
	font-size: 2em;
	color: white;
	transition: all 0.1s ease-in;
	min-width: 28px;
	border-radius: 20%;
	margin: 10px 0px;
}

.sidebar .social-logos li:hover {
	cursor: pointer;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 40%;
}

@media screen and (max-width: 572px) {
	.sidebar {
		display: none;
	}
}
